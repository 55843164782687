import {Component, ElementRef, OnInit} from '@angular/core';
import {ContactUsService} from './services/contact-us';
import {ContactUs} from './model/contact-us';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageService} from './services/message.service';
import {Message as EMessage} from './model/enum/message.enum';
import {Message} from './model/message';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  contactUs = new ContactUs();
  isRequired = false;
  isFile = false;
  isAddFile = true;
  classifications = [];
  files = [{}];
  formContactUs: FormGroup;
  constructor(
    public messageService: MessageService,
    private contactUsService: ContactUsService,
    private formBuilder: FormBuilder,
    private el: ElementRef
  ) {
    this.formContactUs = this.formBuilder.group({
      name: [''],
      email: [''],
      telephone: [''],
      classification: [''],
      title: [''],
      description: [''],
      cpf: ['']
    });
  }

  ngOnInit(): void {
    this.getClassificacao();
  }

  getClassificacao(): void {
    this.contactUsService.classifications()
      .subscribe(res => this.classifications = res);
  }

  onSubmit(form): void {
    if (!form.invalid) {
      this.send(form);
    }
  }

  async send(formContactUs: FormGroup): Promise<void> {
    const formData = new FormData();
    this.contactUs = this.formatFormDatas(formContactUs);

    formData.append('identificador', this.contactUs.cpf);
    formData.append('tipo', 'PF');
    formData.append('nome', this.contactUs.nome);
    formData.append('email', this.contactUs.email);
    formData.append('classificacao', this.contactUs.classificacao);
    formData.append('descricao', `${this.contactUs.titulo}_${this.contactUs.descricao}`);
    formData.append('recebido', 'WEB');
    this.contactUsService.openCalled(formData)
      .subscribe(data => {
          this.clearForm();
          this.messageService.add(new Message(EMessage.SUCCESS, 'Chamado aberto com sucesso'));
        }, err => console.log(err));
  }

  formatFormDatas(formContactUs: FormGroup): any {
    const contactUs = {
      cpf: undefined,
      nome: undefined,
      email: undefined,
      telefone: undefined,
      classificacao: undefined,
      titulo: undefined,
      descricao: undefined
    };
    contactUs.cpf = formContactUs.controls.cpf.value;
    contactUs.nome = formContactUs.controls.name.value;
    contactUs.email = formContactUs.controls.email.value;
    contactUs.telefone = formContactUs.controls.telephone.value;
    contactUs.classificacao = formContactUs.controls.classification.value;
    contactUs.titulo = formContactUs.controls.title.value;
    contactUs.descricao = `${formContactUs.controls.description.value}`;
    return contactUs;
  }

  clearForm(): void {
    this.files = [{}];
    this.formContactUs.reset();
    this.isFile = false;
  }

  getErrorMessage(): string {
    return 'Este campo é obrigatório.';
  }

  getEmailErrorMessage(): string {
    return this.formContactUs.controls.email.hasError('required') ? 'Este campo é obrigatório.' :
      this.formContactUs.controls.email.hasError('pattern') ? 'Email Inválido' :
        '';
  }
}
