<router-outlet></router-outlet>

<footer class="footer">
  <div class="footer-container">
    <div class="container">
      <div class="footer-container_top row align-items-center">
        <div class="col-lg-2 text-center text-lg-left">
          <img src="assets/img/logo-abastece-ai.svg" alt="Logo Abastece aí Ipiranga">
        </div>
        <div class="col-lg-5 text-center text-lg-left py-5 py-lg-0">
          <div class="row">
            <div class="col-12 col-md-6 footer-container_lista-nav_item">
              <a href="#pra-voce" class="footer-container_lista-nav_link">Pra você</a>
            </div>
            <div class="col-12 col-md-6 footer-container_lista-nav_item">
              <a href="#seja-parceiro" class="footer-container_lista-nav_link">Pro parceiro</a>
            </div>
            <div class="col-12 col-md-6 footer-container_lista-nav_item">
              <a href="#como-funciona" class="footer-container_lista-nav_link">Como funciona?</a>
            </div>
            <div class="col-12 col-md-6 footer-container_lista-nav_item">
              <a href="#duvidas" class="footer-container_lista-nav_link">Dúvidas</a>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-5 text-center text-lg-right">
          <h2 class="titulo h2 d-lg-none c-branco">Redes Sociais</h2>
          <ul class="list-unstyled footer-container_lista-social">
            <li class="footer-container_lista-social_item">
              <a href="#" class="footer-container_lista-social_link">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li class="footer-container_lista-social_item">
              <a href="#" class="footer-container_lista-social_link">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li class="footer-container_lista-social_item">
              <a href="#" class="footer-container_lista-social_link">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li class="footer-container_lista-social_item">
              <a href="#" class="footer-container_lista-social_link">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
          </ul>
        </div>

        <div class="footer-store_mobile col-12 text-center d-lg-none">
          <h2 class="titulo h2 d-lg-none c-branco">Disponível nas lojas</h2>
          <div class="d-flex justify-content-center">
            <button class="btn btn-preto btn-radius btn-store mr-3">
              <img src="assets/img/img-playstore.svg" alt="Logo representativo da GooglePlay">
            </button>
            <button class="btn btn-preto btn-store btn-radius">
              <img src="assets/img/img-appstore.svg" alt="Logo representativo da AppStore">
            </button>
          </div>
        </div>
      </div>
      <div class="footer-container_store row">
        <div class="col-lg-5">
          <ul class="footer-container_lista-news list-unstyled">
            <li class="footer-container_lista-news_item">
              <h6 class="footer-container_lista-news_link-titulo c-branco m-0">Encarregado de Proteção de Dados Pessoais</h6>
              <address class="footer-container_lista-news_link-texto c-branco">André Gustavo Zaia: <a href="mailto:privacidade@e-ai.com.br" class="c-azul">privacidade@e-ai.com.br</a></address>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 offset-lg-3 d-none d-lg-block">
          <div class="footer_store">
            <a href="#" class="btn btn-preto btn-radius btn-store mr-3">
              <img src="assets/img/img-playstore.svg" alt="Logo representativo da GooglePlay">
            </a>
            <a href="#" class="btn btn-preto btn-store btn-radius">
              <img src="assets/img/img-appstore.svg" alt="Logo representativo da AppStore">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-address">
    <div class="container">
      <div class="footer-address_container">
        <!-- aumentar a fonte -->
        <p class="footer-address_texto ml-2">Uma empresa do grupo Ultra - Copyright © abastece aí 2020 | Central de Atendimento: Consumidor - 3003-3424. Horário de Atendimento: 2ª à 6ª: 9h às 21h / Sábado: 9h às 18h. | Seja um parceiro abastece aí: 3003-3470. Horário de Atendimento: 2ª à 6ª: 7h às 19h / Sábado: 8h às 13h.</p>
      </div>
      <!--<p class="footer-address_texto ml-2">Endereço: Rua Exemplo 1238 - Rio de Janeiro, RJ</p>-->
    </div>
  </div>
</footer>
