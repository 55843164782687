import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PixRoutingModule } from './pix-routing.module';
import { PixComponent } from './pix.component';


@NgModule({
  declarations: [PixComponent],
  imports: [
    CommonModule,
    PixRoutingModule
  ]
})
export class PixModule { }
