export class ContactUs {
  constructor(
    public nome?: string,
    public email?: string,
    public cpf?: string,
    public telefone?: string,
    public classificacao?: string,
    public titulo?: string,
    public descricao?: string,
    public mensagem?: string
  ) { }
}
