export const environment = {
  production: false,
  contactUs: {
    fileupload: 'https://a1ap581b6l.execute-api.us-east-1.amazonaws.com/hml/fileupload',
    clientId: 'd4792cb30bfb4c65b3445ef8951fa528',
    clientSecret: '30570e9876e74b3899F55D881C1427BD',
    assunto: 'https://x79y7fvhsb.execute-api.us-east-1.amazonaws.com/HML/listar',
    classification: 'https://87ucs0oey0.execute-api.us-east-1.amazonaws.com/HML/listar'
  },
  url: 'https://authkmv.kmdevantagens.com.br'
};
