import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  constructor(
    private http: HttpClient
  ) { }

  classifications(): Observable<any> {
    const url = environment.contactUs.classification;
    return this.http.get<any>(url, this.httpOptions)
      .pipe(map(res => res.classificacoes));
  }

  openCalled(formdata: FormData): Observable<any> {
    this.httpOptions.headers = this.httpOptions.headers.set('clientSecret', environment.contactUs.clientSecret);
    this.httpOptions.headers = this.httpOptions.headers.set('clientId', environment.contactUs.clientId);
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiaXBwZmFsZWNvbm9zY28iLCJwYXNzd29yZCI6IjFwcEY0bDJDOG44c2M4In0.yFIg7zX9Z8RKzjWSyb-E7bd3MWRC9_3p4zii2SkGZak');
    this.httpOptions.headers = this.httpOptions.headers.set('Content-Type', 'multipart/form-data');
    const url = environment.contactUs.fileupload;
    return this.http.post(url, formdata, this.httpOptions)
      .pipe(map(result => result),
        catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
}
