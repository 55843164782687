import { Injectable } from '@angular/core';
import {Message} from '../model/message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public message: Message = null;

  constructor() { }

  add(message: Message): void {
    this.message = message;
  }

  clear(): void {
    console.log('clear');
    this.message = null;
  }
}
