<header class="header">
  <div class="container">
    <div class="d-none d-lg-flex align-items-center">
      <a class="logo">
        <img src="assets/img/logo-abastece-ai.svg" alt="Logo Abasteceai">
      </a>
      <nav class="header_nav">
        <ul class="list-unstyled header_nav_links">
          <li>
            <a routerLink="" class="header_nav_link">Início</a>
          </li>
          <li>
            <a href="#chave" class="header_nav_link">Chave PIX</a>
          </li>
          <li>
            <a href="#datas" class="header_nav_link">Datas</a>
          </li>
          <li>
            <a href="#vantagens" class="header_nav_link">Vantagens</a>
          </li>
        </ul>
      </nav>
    </div>
    <button class="btn btn-menu d-lg-none">menu</button>
    <nav class="header_nav d-lg-none">
      <div class="logos">
        <img src="assets/img/logo-abastece-ai.svg" alt="Logo abastece ai" class="logo abasteceai">
        <img src="assets/img/pix-logo-white.svg" alt="Logo Pix" class="logo pix">
      </div>
      <ul class="list-unstyled header_nav_links">
        <li class="active">
          <a routerLink="" class="header_nav_link">Início</a>
        </li>
        <li>
          <a href="#chave" class="header_nav_link">Chave PIX</a>
        </li>
        <li>
          <a href="#datas" class="header_nav_link">Datas</a>
        </li>
        <li>
          <a href="#vantagens" class="header_nav_link">Vantagens</a>
        </li>
      </ul>
      <ul class="redes-sociais mb-0 list-unstyled">
        <li><a href="https://www.facebook.com/ipirangaoficial" target="_blank" class="fa fa-facebook-f"></a></li>
        <li><a href="https://www.instagram.com/ipiranga/" target="_blank" class="fa fa-instagram"></a></li>
        <li><a href="https://twitter.com/ipiranga" target="_blank" class="fa fa-twitter"></a></li>
      </ul>
    </nav>
  </div>
</header>

<section class="banner" id="banner">
  <div class="container">
    <div class="banner_conteudo">
      <img src="assets/img/logo-abastece-ai.svg" alt="Logo Abastece aí Ipiranga" class="banner_logo">
      <h1 class="titulo h1 c-branco"><img src="assets/img/pix-losango.svg" class="banner_pix" alt="Pix"> é no<br/>abastece aí</h1>
      <p class="banner_texto">Uma nova forma de fazer transferências e pagamentos</p>
      <img src="assets/img/pix-logo-white.svg" alt="Logo Pix" class="banner_logo-pix">
    </div>
  </div>
  <img src="assets/img/img-banner.png" alt="Imagem ilustrativa" class="banner_img">
</section>

<div class="sect-sobre">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-4">
        <h2 class="titulo h2 c-amarelo">O que é o<br>PIX?</h2>
        <p class="mb-0">O PIX é um novo meio de pagamento e transferência instantânea de saldo entre contas de qualquer instituição financeiro ou de pagamentos.</p>
      </div>
      <div class="col-12 col-md-7 offset-md-1 mt-5 mt-md-0">
        <ul class="list-unstyled lista-logos">
          <li><img src="assets/img/logo-abastece-ai.svg" alt=""></li>
          <li><img src="assets/img/pix-icone-white.svg" alt=""></li>
        </ul>
        <p class="mb-0">Com Pix no seu app abastece aí, você vai poder pagar estabelecimentos e transferir dinheiro para qualquer pessoa. O pagamento cai em segundos e você não precisa saber o número da agência e conta para realizar ou receber transferências, apenas as chaves Pix, que podem ser, por exemplo, o CPF, o e-mail e o celular.</p>
      </div>
    </div>
  </div>
</div>

<section class="container">
  <ul class="list-unstyled lista-padrao">
    <li>
      <div class="seu-carro_img">
        <img src="assets/img/icone-banco-central.svg" alt="Compra de Combustível com Abastece Aí Ipiranga">
      </div>
      <p><b>Criado pelo Banco<br/>Central</b></p>
    </li>
    <li class="divider">
      <div class="circle circle-rosa"></div>
    </li>
    <li>
      <div class="seu-carro_img">
        <img src="assets/img/icone-transferencia.svg" alt="Pague pelo celular com abastece aí">
      </div>
      <p><b>Rápido e prático de pagar<br/>e transferir dinheiro</b></p>
    </li>
    <li class="divider">
      <div class="square square-verde"></div>
    </li>
    <li>
      <div class="seu-carro_img">
        <img src="assets/img/icone-calendario.svg" alt="Aproveite os Descontos Abastece Aí Relâmpagos">
      </div>
      <p><b>24 horas por dia, 7 dias<br/>por semana e feriados</b></p>
    </li>
  </ul>
</section>

<section class="sect-destaque">
  <div class="container">
    <p class="sect-destaque_texto">O Pix poderá ser utilizado não só para transferir o dinheiro para um amigo, mas também para pagar compras e até tarifas e impostos para o governo.</p>
  </div>
</section>

<section id="chave" class="sect-chave_pix">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-5 d-flex align-items-md-center align-items-lg-start justify-content-center justify-content-md-start">
        <img src="assets/img/img-sect_chave-pix.png" alt="" class="sect-chave_pix-img"/>
      </div>
      <div class="col-12 col-md-7">
        <h2 class="titulo h2">O que é a chave PIX?</h2>
        <p class="mb-0">As chaves PIX vão substituir as informações de banco  e conta, ou seja, funcionam como um apelido para os dados bancários tradicionais. Cada pessoa poderá cadastrar até cinco chaves por conta, que podem ser o número de celular, o CPF, o e-mail ou mais duas chaves aleatórias que são um código gerado automaticamente pelo Banco Central. <br/><br/>Agora, você não precisa mais compartilhar seus dados bancários, as chaves Pix cumprem essa função e você recebe e envia transferências em apenas 10 segundos.</p>
        <p class="sect-chave_pix-obs">A chave é segura e só serve pra alguém te pagar ou você pagar alguém, nunca alguém vai poder tirar dinheiro da sua conta.</p>
      </div>
    </div>
  </div>
</section>

<section id="datas" class="datas-lancamento">
  <div class="container">
    <h2 class="titulo h2">Datas de lançamento do PIX</h2>
    <ol class="list-unstyled datas">
      <li class="card-data">
        <h3 class="card-titulo">25 set</h3>
        <div class="square square-rosa"></div>
        <p>Faça seu pré-cadastro no app abastece aí e no dia 05 de outubro enviamos uma notificação com a confirmação de cadastro.</p>
      </li>
      <li class="card-data">
        <h3 class="card-titulo">05 out</h3>
        <div class="circle circle-verde"></div>
        <p>Você já poderá cadastrar a chave Pix vinculada a conta abastece aí e deixar sua conta pronta pra usar todas as facilidades do Pix.</p>
      </li>
      <li class="card-data destaque">
        <h3 class="card-titulo">16 nov</h3>
        <div class="square square-amarelo"></div>
        <p>O PIX estará liberado para quem quiser usar: pagar, transferir e receber sem se preocupar com horário e dia de semana. </p>
      </li>
    </ol>
    <p>Faça o pré-cadastro das suas chaves PIX aqui no abastece aí e tenha muito mais facilidade em mandar dinheiro para sua conta, resgatar o seu cashback e fazer outros tipos de pagamento! Ao realizar seu pré-cadastro, em 05 de outubro será notificado quando a chave estiver vinculada e ativa na sua conta Abastece Aí. A partir de novembro você não precisará mais compartilhar seus dados bancários para receber transferências ou pagamentos.Facilite sua vida! É só aguardar!</p>
  </div>

  <img src="assets/img/img-data-left.svg" alt="" class="img bottom left" aria-hidden="true">
  <img src="assets/img/img-data-right.svg" alt="" class="img top right" aria-hidden="true">
</section>

<section class="sect-vantagens" id="vantagens">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-7 col-lg-6 offset-lg-1">
        <h2 class="titulo h2 c-preto">Vantagens do PIX no abastece aí</h2>
        <ul class="passos">
          <li>
            <span class="passos-num">1</span>
            <div class="passos-info">
              <p>É mais rápido e prático.</p>
            </div>
          </li>
          <li>
            <span class="passos-num">2</span>
            <div class="passos-info">
              <p>O dinheiro é disponibilizado em questões de segundos.</p>
            </div>
          </li>
          <li>
            <span class="passos-num">3</span>
            <div class="passos-info">
              <p>Você não precisa mais aguardar o expediente bancário para realizar ou receber uma transferência.</p>
            </div>
          </li>
          <li>
            <span class="passos-num">4</span>
            <div class="passos-info">
              <p>Para efetuar um pagamento o usuário poderá escolher entre utilizar uma das Chaves de Pix ou ler QR Code.</p>
            </div>
          </li>
          <li>
            <span class="passos-num">5</span>
            <div class="passos-info">
              <p>Hoje para realizar um TED ou DOC é necessário saber o Nome, CPF/CNPJ, banco, agência e número da conta. Com o PIX esse processo será muito mais simples!</p>
            </div>
          </li>
        </ul>
      </div>
      <img src="assets/img/img-sect_vantagens.png" class="img-sect_vantagens" alt=""/>
    </div>
  </div>
</section>

<section class="container sect-faq">
  <h2 class="titulo h2 text-center">Dúvidas sobre o novo PIX? <br> <span class="small-mobile">A gente responde!</span></h2>
  <div class="row justify-content-center">
    <div class="col-12 col-lg-10">
      <div class="accordion faq" id="accordionFaq">
        <div class="card">
          <div class="card-header" id="faq1">
            <h2 class="mb-0">
              <button class="btn faq-btn collapsed" type="button" data-toggle="collapse" data-target="#resposta1" aria-expanded="false" aria-controls="resposta1">
                <p class="card-title">É Seguro?</p>
                <span class="card-icon"></span>
              </button>
            </h2>
          </div>

          <div id="resposta1" class="collapse" aria-labelledby="faq1" data-parent="#accordionFaq">
            <div class="card-body">
              <p>Sim, o PIX é um novo tipo de pagamento criado pelo Banco Central, e passou por todos os requisitos de segurança exigidos. Um jeito mais acessível e simples de movimentar o seu dinheiro.</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="faq2">
            <h2 class="mb-0">
              <button class="btn faq-btn" type="button" data-toggle="collapse" data-target="#resposta2" aria-expanded="true" aria-controls="resposta2">
                <p class="card-title">Posso transferir para outros bancos?</p>
                <span class="card-icon"></span>
              </button>
            </h2>
          </div>
          <div id="resposta2" class="collapse show" aria-labelledby="faq2" data-parent="#accordionFaq">
            <div class="card-body">
              <p>Sim, você pode utilizar o PIX de qualquer instituição que aceite esse meio para qualquer outra que também aceite. O Pix vem para simplificar as transferências pra qualquer banco ou app de pagamentos. Você poderá transferir ou receber em segundos, não importa a hora ou o dia. </p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="faq3">
            <h2 class="mb-0">
              <button class="btn faq-btn collapsed" type="button" data-toggle="collapse" data-target="#resposta3" aria-expanded="false" aria-controls="resposta3">
                <p class="card-title">O que é a chave aleatória? </p>
                <span class="card-icon"></span>
              </button>
            </h2>
          </div>
          <div id="resposta3" class="collapse" aria-labelledby="faq3" data-parent="#accordionFaq">
            <div class="card-body">
              <p>A chave aleatória também chamado de endereçamento virtual de pagamento é um conjunto de 32 caracteres que o Banco Central cria automaticamente. Ela é uma opção de chave para quem não quer passar o número do celular, o e-mail ou o CPF sempre que for receber uma transferência.</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="faq4">
            <h2 class="mb-0">
              <button class="btn faq-btn collapsed" type="button" data-toggle="collapse" data-target="#resposta4" aria-expanded="false" aria-controls="resposta4">
                <p class="card-title">Vou poder pagar e realizar compras em estabelecimentos comerciais?</p>
                <span class="card-icon"></span>
              </button>
            </h2>
          </div>
          <div id="resposta4" class="collapse" aria-labelledby="faq4" data-parent="#accordionFaq">
            <div class="card-body">
              <p>Sim. Dependerá apenas da empresa que você estiver comprando. O estabelecimento deverá disponibilizar o Pix como meio de pagamento e você vai poderá realizar suas compras de onde estiver.</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="faq5">
            <h2 class="mb-0">
              <button class="btn faq-btn collapsed" type="button" data-toggle="collapse" data-target="#resposta5" aria-expanded="false" aria-controls="resposta5">
                <p class="card-title">Tenho mais de 1 conta. Posso incluir todas no PIX? </p>
                <span class="card-icon"></span>
              </button>
            </h2>
          </div>
          <div id="resposta5" class="collapse" aria-labelledby="faq5" data-parent="#accordionFaq">
            <div class="card-body">
              <p>Sim. Você pode usar chaves distintas para vincular as diferentes contas. Por exemplo, usar o número de telefone celular vinculado à conta corrente da instituição X, usar o CPF vinculado à conta p da instituição Y, usar o e-mail vinculado à conta de pagamento da instituição Z, etc.</p>
              <p>Vale lembrar que não é possível vincular uma mesma chave a mais de uma conta.</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="faq6">
            <h2 class="mb-0">
              <button class="btn faq-btn collapsed" type="button" data-toggle="collapse" data-target="#resposta6" aria-expanded="false" aria-controls="resposta6">
                <p class="card-title">Ao tentar cadastrar uma chave fui informado de que ela já pertence a outro usuário do PIX. O que devo fazer?</p>
                <span class="card-icon"></span>
              </button>
            </h2>
          </div>
          <div id="resposta6" class="collapse" aria-labelledby="faq6" data-parent="#accordionFaq">
            <div class="card-body">
              ​<p>Nesse caso, você poderá iniciar um procedimento de reivindicação de posse da chave direto no app Abastece Aí. </p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="faq7">
            <h2 class="mb-0">
              <button class="btn faq-btn collapsed" type="button" data-toggle="collapse" data-target="#resposta7" aria-expanded="false" aria-controls="resposta7">
                <p class="card-title">Troquei meu número de celular e gostaria de atualizar a chave de endereçamento. O que devo fazer? </p>
                <span class="card-icon"></span>
              </button>
            </h2>
          </div>
          <div id="resposta7" class="collapse" aria-labelledby="faq" data-parent="#accordionFaq">
            <div class="card-body">
              <p>​Nesse caso, você precisará incluir uma nova chave usando seu novo número de telefone celular e excluir a chave referente ao número antigo.</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="faq8">
            <h2 class="mb-0">
              <button class="btn faq-btn collapsed" type="button" data-toggle="collapse" data-target="#resposta8" aria-expanded="false" aria-controls="resposta8">
                <p class="card-title">Mudei a minha conta como atualizar a minha chave? </p>
                <span class="card-icon"></span>
              </button>
            </h2>
          </div>
          <div id="resposta8" class="collapse" aria-labelledby="faq" data-parent="#accordionFaq">
            <div class="card-body">
              <p>Se você tem uma chave já vinculada a uma conta de outra instituição e gostaria de transferi-la para a conta Abastece Aí, é necessário realizar a solicitação de portabilidade no app abastece aí. Para isso, ao receber o pedido de confirmação da sua instituição de origem, você deverá acessar app dessa instituição e confirmar a portabilidade. Caso você já tenha encerrado a conta no banco de origem e, portanto, não tenha mais acesso ao aplicativo para efetuar a confirmação da portabilidade, essa instituição terá a obrigação de excluir a chave. Assim, ao invés de efetuar a portabilidade, você poderá simplesmente fazer um novo registro da chave.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="sect-download">
  <div class="container">
    <div class="row align-items-md-center">
      <div class="col-md-6 col-lg-5 offset-lg-2">
        <h2 class="titulo h2 c-azul">Baixe agora mesmo!</h2>
        <p class="sect-download_subtitulo">É pro carro. É pra vida. É cashback e benefícios pra valer.</p>
        <div class="sect-download_stores">
          <div>
            <a class="btn btn-block btn-radius btn-store btn-preto" target="_blank" aria-label="Download app Google Play" tabindex="0">
              <img src="assets/img/img-playstore.svg" alt="Imagem representativa do logo da Playstore">
            </a>
            <a class="btn btn-block btn-radius btn-store btn-preto" target="_blank" aria-label="Download app Apple Store" tabindex="0">
              <img src="assets/img/img-appstore.svg" alt="Imagem representativa do logo da Applestore">
            </a>
          </div>
          <img class="qr-code_download d-none d-md-block" src="assets/img/qr-code_download.png" alt="Imagem representativa de um qrcode pra download.">
          <img class="img-celular-mobile" src="assets/img/img-celular.png" aria-hidden="true" alt="">
        </div>
      </div>
      <div class="col-md-6 col-lg-3 d-none d-md-block">
        <img src="./img/img-celular.png" alt="">
      </div>
    </div>
  </div>
</section>
