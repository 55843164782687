<header class="header scrolled">
  <div class="container">
    <div class="header_wrapper">
      <a href="#banner">
        <img src="assets/img/logo-abastece-ai.svg" alt="Logo Abastece aí Ipiranga" class="header_logo desktop">
        <img src="assets/img/logo-abastece-ai-mobile.svg" class="header_logo scroll" alt="Logo Abastece aí Ipiranga">
      </a>
    </div>
    <nav class="header-nav">
      <img src="assets/img/img-nav-mobile.svg" alt="" class="header-nav_img">
      <button type="button" class="btn-voltar"><img src="assets/img/icone-seta-lg-left.svg" alt=""> Voltar</button>
      <a href="#banner">
        <img src="assets/img/logo-abastece-ai.svg" alt="Logo Abastece aí Ipiranga" class="header_logo">
      </a>
      <ul class="list-unstyled header-nav_lista">
        <li class="header-nav_item">
          <a href="#pra-voce">Pra você</a>
        </li>
        <li class="header-nav_item">
          <a routerLink="/pix">Chave PIX</a>
        </li>
        <!-- <li class="header-nav_item active">
            <a href="#seja-parceiro">Pro parceiro</a>
        </li>-->
        <li class="header-nav_btn d-none">
          <a href="#" target="_blank" rel="noopener noreferrer" class="btn btn-radius btn-amarelo">Baixe o APP aí</a>
        </li>
      </ul>
      <!-- <ul class="list-unstyled header-nav_sociais">
        <li>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </li>
        <li>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <i class="fab fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <i class="fab fa-instagram"></i>
          </a>
        </li>
        <li>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <i class="fab fa-twitter"></i>
          </a>
        </li>
      </ul> -->
    </nav>
  </div>
</header>
<button type="button" class="header-nav_toggle scrolled">
  <div class="line-wrapper">
    <span class="line"></span>
    <span class="line"></span>
    <span class="line circle"></span>
  </div>
</button>
<section class="banner" id="banner" role="banner">
  <div class="container">
    <div class="banner_conteudo">
      <img src="assets/img/logo-abastece-ai.svg" alt="Logo Abastece aí Ipiranga" class="banner_logo">
      <h1 class="titulo h1 c-branco"> É pro carro. É pra vida. <br> É  <img src="assets/img/img-cashback-losango.svg" class="cashback" alt="cashback"> e benefícios<br class="d-none d-lg-block"> pra valer.</h1>
      <p class="banner_texto">o <strong>clube abastece aí</strong> chegou com uma nova jornada, pra você aproveitar diversos <strong>benefícios</strong> pro seu <strong>carro</strong> e no seu <strong>dia a dia</strong>.</p>
      <p class="banner_texto"><strong>baixe aí ;)</strong></p>

      <div class="banner_store">
        <a href="https://play.google.com/store/apps/details?id=com.gigigo.ipirangaconectcar&hl=pt_BR" target="_blank" rel="noopener noreferrer" class="btn btn-radius btn-preto btn-store">
          <img src="assets/img/img-playstore.svg" alt="Google PlayStore">
        </a>
        <a href="https://apps.apple.com/br/app/abastece-a%C3%AD-ofertas-ipiranga/id1052059383" target="_blank" rel="noopener noreferrer" class="btn btn-radius btn-preto btn-store">
          <img src="assets/img/img-appstore.svg" alt="Apple AppStore">
        </a>
      </div>

      <div class="banner_mais">
        <div>
          <div class="banner_mais-mouse">
            <img src="assets/img/icone-mouse.svg" alt="">
            <img src="assets/img/icone-seta-mouse.svg" alt="" class="banner_mais-seta">
          </div>
          <p class="banner_mais-texto">
            <b>Role para baixo</b> <br>
            para descobrir mais
          </p>
        </div>
        <div class="qrcode">
          <img src="assets/img/abastece-qrcode.png" alt="qrcode">
        </div>
      </div>






      <!-- <a href="#" target="_blank" rel="noopener noreferrer" class="btn btn-amarelo btn-block banner_store-mobile no-hover d-none" style="transform: translateY(-249.962px);">Baixe o app aí</a> -->
    </div>
  </div>
  <img src="assets/img/banner-abastece-ai.svg" alt="Banner Abastece aí Ipiranga" class="banner_img-desktop">
  <img src="assets/img/banner-abastece-ai-mobile-1.png" alt="Banner Abastece aí Ipiranga" class="banner_img-mobile top">
  <img src="assets/img/banner-abastece-ai-mobile-2.png" alt="Banner Abastece aí Ipiranga" class="banner_img-mobile bottom">
</section>

<div class="sect-promo-corolla">
  <div class="container">
    <div class="row">
      <div class="col col-md-6 imgs">
        <img class="imgs_logo" src="assets/img/img-banner-corolla-logo.png" alt="Logo da promoção. Use o app e concorra, 10 corollas híbridos e milhares de créditos no abastece aí.">
        <img class="imgs_carro" src="assets/img/img-banner-corolla-carro.png" alt="Meramente uma imagem auxiliar de visual da promoção. Imagem de um carro.">
        <img class="imgs_aux1" src="assets/img/img-banner-corolla-folha-1.png" alt="Meramente uma imagem auxiliar de visual da promoção. Imagem de uma folha.">
        <img class="imgs_aux2" src="assets/img/img-banner-corolla-folha-2.png" alt="Meramente uma imagem auxiliar de visual da promoção. Imagem de uma folha.">
        <img class="imgs_aux3" src="assets/img/img-banner-corolla-folha-3.png" alt="Meramente uma imagem auxiliar de visual da promoção. Imagem de uma folha.">
      </div>
      <div class="col-12 col-md-6 col-lg-5 offset-lg-1 col-xl-5 infos">
        <img src="assets/img/logo-ipiranga.svg" alt="Logo Ipiranga" class="infos_logo">
        <h2 class="titulo h2 infos_titulo">promoção no posto ipiranga</h2>
        <p class="infos_texto">em qualquer pagamento com o app na ipiranga você já está concorrendo!</p>
        <a href="https://www.kmdevantagens.com.br/wps/portal/Application/MarketPlace/c/promocoes/p/10-corollas-hibridos/sobre-a-promocao" target="_blank" class="btn btn-amarelo btn-radius infos_btn" tabindex="0" aria-label="Clique para acessar a página com amis detalhes sobre a promoção.">saiba mais</a>
        <ul class="list-unstyled d-flex align-items-center infos_lista">
          <li><img src="assets/img/logo-ampm.svg" alt=""></li>
          <li class="ml-3"><img src="assets/img/logo-jetoil.svg" alt=""></li>
          <li class="ml-3"><img src="assets/img/logo-dt-clean.svg" alt=""></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<section class="sect-video">
  <img src="assets/img/img-sect-video-1.svg" alt="" class="sect-video_img left">
  <img src="assets/img/img-sect-video-2.svg" alt="" class="sect-video_img right">
  <div class="container text-center">
    <h2 class="titulo h2 text-lowercase">Conheça o Novo Abastece Aí</h2>
    <iframe class="sect-video_iframe" src="https://www.youtube.com/embed/-roY2c4IJZA"></iframe>
  </div>
</section>

<section id="pra-voce" class="sect-seu-carro container">
  <h2 class="titulo h2 text-center text-lowercase">pro seu carro…</h2>
  <ul class="list-unstyled seu-carro-lista">
    <li class="animate" style="animation-delay: 0s;">
      <div class="seu-carro_img">
        <img src="assets/img/compra-de-combustivel-abastece-ai.svg" alt="Compra de Combustível com Abastece Aí Ipiranga">
      </div>
      <p><b>abasteça nos postos Ipiranga e ganhe cashback​</b></p>
    </li>
    <li class="divider animate" style="animation-delay: 0.2s;">
      <div class="circle circle-rosa"></div>
    </li>
    <li class="animate" style="animation-delay: 0.4s;">
      <div class="seu-carro_img">
        <img src="assets/img/pagamento-pelo-celular-abastece-ai.svg" alt="Pague pelo celular com abastece aí">
      </div>
      <p><b>pagamento rápido, seguro e sem contato físico</b></p>
    </li>
    <li class="divider animate" style="animation-delay: 0.6s;">
      <div class="square square-verde"></div>
    </li>
    <li class="animate" style="animation-delay: 0.8s;">
      <div class="seu-carro_img">
        <img src="assets/img/aproveite-descontos-abastece-ai.svg" alt="Aproveite os Descontos Abastece Aí Relâmpagos">
      </div>
      <p><b>pague no Jet Oil e na AmPm e ganhe cashback</b></p>
    </li>
  </ul>
</section>

<section class="sect-sua-vida container">
  <h2 class="titulo h2 text-center">pra sua vida...</h2>
  <div class="row">
    <div class="col-12 col-xl-9 offset-xl-2 sua-vida_carousel-container">
      <div class="owl-carousel owl-theme sua-vida_carousel owl-loaded">
        <div class="owl-stage-outer">
          <div class="owl-stage" style="transform: translate3d(-3420px, 0px, 0px); transition: all 0s ease 0s; width: 5985px;">

            <div class="owl-item" style="width: 855px;">
              <div class="item">
                <div class="item_conteudo">
                  <h4 class="titulo h4 c-preto text-lowercase">Abastece aí e Km de Vantagens juntos no mesmo app</h4>
                  <p class="item_texto text-lowercase">Unimos forças e agora você pode trocar Km e efetuar pagamentos nos parceiros do programa. Tudo isso pelo celular, dentro do mesmo aplicativo e ainda ganha cashback</p>
                </div>
                <div class="item_img">
                  <img src="assets/img/img-sua-vida-6.png" alt="" class="mockup">
                  <img src="assets/img/detalhe-sua-vida-6.svg" alt="" class="detalhe seis">
                </div>
              </div>
            </div>

            <div class="owl-item" style="width: 855px;">
              <div class="item">
                <div class="item_conteudo">
                  <h4 class="titulo h4 c-preto text-lowercase">Cashback <br class="d-none d-md-block"> pra valer</h4>
                  <p class="item_texto text-lowercase">Você troca seus Km por cupons dos nossos parceiros e ao utilizar, ganha CashBack para gastar como quiser</p>
                </div>
                <div class="item_img">
                  <img src="assets/img/cashback-abastece-ai.png" alt="Conheça o Cashback Abastece Aí" class="mockup">
                  <img src="assets/img/detalhe-sua-vida-1.svg" alt="" class="detalhe um">
                </div>
              </div>
            </div>

            <div class="owl-item" style="width: 855px;">
              <div class="item">
                <div class="item_conteudo">
                  <h4 class="titulo h4 c-preto text-lowercase">Pagamentos além <br class="d-none d-md-block">da Ipiranga</h4>
                  <p class="item_texto text-lowercase">Agora você pode fazer pagamentos rápidos e ganhar CashBack em lojas físicas e online</p>
                </div>
                <div class="item_img">
                  <img src="assets/img/img-sua-vida-2.png" alt="" class="mockup">
                  <img src="assets/img/detalhe-sua-vida-2.svg" alt="" class="detalhe dois">
                </div>
              </div>
            </div>

            <div class="owl-item" style="width: 855px;">
              <div class="item">
                <div class="item_conteudo">
                  <h4 class="titulo h4 c-preto text-lowercase">Transferência entre contas abastece aí</h4>
                  <p class="item_texto text-lowercase">Pagar e receber dinheiro ficou bem mais fácil, É só fazer a leitura de QR Code e pronto</p>
                </div>
                <div class="item_img">
                  <img src="assets/img/img-sua-vida-3.png" alt="" class="mockup">
                  <img src="assets/img/detalhe-sua-vida-3.svg" alt="" class="detalhe tres">
                </div>
              </div>
            </div>

            <div class="owl-item active" style="width: 855px;">
              <div class="item">
                <div class="item_conteudo">
                  <h4 class="titulo h4 c-preto text-lowercase">Transferência do seu saldo para qualquer outro banco</h4>
                  <p class="item_texto text-lowercase">No abastece aí, o dinheiro em conta é seu! Você pode fazer o que quiser com ele, inclusive, enviar o saldo para outro banco</p>
                </div>
                <div class="item_img">
                  <img src="assets/img/img-sua-vida-4.png" alt="" class="mockup">
                  <img src="assets/img/detalhe-sua-vida-4.svg" alt="" class="detalhe quatro">
                </div>
              </div>
            </div>

            <div class="owl-item" style="width: 855px;">
              <div class="item">
                <div class="item_conteudo">
                  <h4 class="titulo h4 c-preto text-lowercase">Adicione dinheiro  <br class="d-none d-md-block">na sua conta</h4>
                  <p class="item_texto text-lowercase">Abasteça sua conta de dinheiro e tenha muitos benefícios, Você pode fazer depósitos por boleto e também por transferência bancária</p>
                </div>
                <div class="item_img">
                  <img src="assets/img/img-sua-vida-5.png" alt="" class="mockup">
                  <img src="assets/img/detalhe-sua-vida-5.svg" alt="" class="detalhe cinco">
                </div>
              </div>
            </div>

            <div class="owl-item" style="width: 855px;">
              <div class="item">
                <div class="item_conteudo">
                  <h4 class="titulo h4 c-preto text-lowercase">Acompanhe todas as suas movimentações dentro do app</h4>
                  <p class="item_texto text-lowercase">Administre seu saldo, pagamentos, entradas de cashback e Km, Tudo no mesmo app</p>
                </div>
                <div class="item_img">
                  <img src="assets/img/img-sua-vida-7.png" alt="" class="mockup">
                  <img src="assets/img/detalhe-sua-vida-7.svg" alt="" class="detalhe sete">
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- <div class="owl-nav">
          <button type="button" role="presentation" class="owl-prev"><i class="fas fa-chevron-left"></i></button>
          <button type="button" role="presentation" class="owl-next"><i class="fas fa-chevron-right"></i></button>
        </div> -->
        <div class="owl-dots disabled"></div>
      </div>

      <p class="sua-vida_carousel-counter">5/7</p>

    </div>
  </div>
</section>

<section class="sect-parceiros container">
  <h2 class="titulo h2 text-center">... e em diversos parceiros</h2>

  <div class="row d-none d-lg-flex">
    <div class="col-lg-6 col-md-6">
      <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
        <article class="card-parceiro card-parceiro_lg animate" data-anime="left">
          <div class="card-parceiro_img" style="background-image: url(assets/img/img-parceiro-wine.jpg);"></div>
          <div class="card-parceiro_conteudo">
            <h5 class="titulo h5 c-branco">Não pode faltar na sua adega</h5>
            <p class="card-parceiro_texto">15% de cashback todo mês para garantir os melhores rótulos de vinho</p>
            <img src="assets/img/logo-wine.png" alt="Wine" class="card-parceiro_logo">
          </div>
        </article>
      </a>
    </div>
    <!--<div class="col-lg-3 col-md-6">
      <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
        <article class="card-parceiro animate" data-anime="top">
          <div class="card-parceiro_img" style="background-image: url(assets/img/img-parceiro-camicado.jpg);"></div>
          <div class="card-parceiro_conteudo">
            <h5 class="titulo h5 c-branco">Deixar sua casa ainda mais bonita</h5>
            <p class="card-parceiro_texto">10% de cashback para decorar sua casa</p>
            <img src="assets/img/logo-camicado.png" alt="Wine" class="card-parceiro_logo">
          </div>
        </article>
      </a>
    </div>-->
    <div class="col-lg-6 col-md-6">
      <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
        <article class="card-parceiro card-parceiro_lg animate" data-anime="top">
          <div class="card-parceiro_img" style="background-image: url(assets/img/img-parceiro-netshoes.jpg);"></div>
          <div class="card-parceiro_conteudo">
            <h5 class="titulo h5 c-branco">Comprar aquele tênis novo de corrida</h5>
            <p class="card-parceiro_texto">Até 20% de desconto em artigos esportivos</p>
            <img src="assets/img/logo-netshoes.png" alt="Wine" class="card-parceiro_logo">
          </div>
        </article>
      </a>
    </div>
    <div class="col-lg-3 col-md-6">
      <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
        <article class="card-parceiro animate" data-anime="bottom">
          <div class="card-parceiro_img" style="background-image: url(assets/img/img-mobly.jpg);"></div>
          <div class="card-parceiro_conteudo">
            <h5 class="titulo h5 c-branco">Compre coisas pra sua casa</h5>
            <p class="card-parceiro_texto">Os móveis mais lindos com 7% de desconto</p>
            <!-- <img src="assets/img/logo-mobly.png" alt="Wine" class="card-parceiro_logo"> -->
          </div>
        </article>
      </a>
    </div>
    <!--<div class="col-lg-6 col-md-6">
      <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
        <article class="card-parceiro card-parceiro_lg animate" data-anime="top">
          <div class="card-parceiro_img" style="background-image: url(assets/img/img-parceiro-centauro.jpg);"></div>
          <div class="card-parceiro_conteudo">
            <h5 class="titulo h5 c-branco">Pra você se exercitar</h5>
            <p class="card-parceiro_texto">9% de cashback para aproveitar o melhor do esporte</p>
            &lt;!&ndash; <img src="assets/img/logo-centauro.png" alt="Wine" class="card-parceiro_logo"> &ndash;&gt;
          </div>
        </article>
      </a>
    </div>-->
    <div class="col-lg-6 col-md-6">
      <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
        <article class="card-parceiro card-parceiro_lg animate" data-anime="top">
          <div class="card-parceiro_img" style="background-image: url('assets/img/img-card-latam-pass.jpg');"></div>
          <div class="card-parceiro_conteudo">
            <h5 class="titulo h5 c-branco">Pra você conhecer o mundo</h5>
            <p class="card-parceiro_texto">Troque seus Km por pontos LATAM Pass e aproveite as melhores ofertas</p>
            <img src="assets/img/img-logo-latam-pass.png" alt="Logo da Latam Pass" class="card-parceiro_logo">
          </div>
        </article>
      </a>
    </div>
    <div class="col-md-6 col-lg-3">
      <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
        <article class="card-parceiro animate" data-anime="bottom">
          <div class="card-parceiro_img" style="background-image: url(assets/img/img-parceiro-movida.jpg);"></div>
          <div class="card-parceiro_conteudo">
            <h5 class="titulo h5 c-branco">Alugue um carro para sua viagem</h5>
            <p class="card-parceiro_texto">20% de desconto no aluguel do seu carro</p>
            <img src="assets/img/logo-movida.png" alt="Wine" class="card-parceiro_logo">
          </div>
        </article>
      </a>
    </div>
  </div>

  <div class="sect-parceiros_carousel owl-carousel owl-theme d-lg-none owl-loaded owl-drag " style="">

    <div class="owl-stage-outer">
      <div class="owl-stage" style="transform: translate3d(0px, 0px, 0px); transition: all 0s ease 0s; width: 1340px; padding-left: 30px; padding-right: 30px;">

        <div class="owl-item active" style="width: 203.333px; margin-right: 10px;">
          <div class="item">
            <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
              <article class="card-parceiro card-parceiro_lg">
                <div class="card-parceiro_img" style="background-image: url(assets/img/img-parceiro-wine.jpg);"></div>
                <div class="card-parceiro_conteudo">
                  <h5 class="titulo h5 c-branco">Não pode faltar na sua adega</h5>
                  <p class="card-parceiro_texto">15% de cashback todo mês para garantir os melhores rótulos de vinho</p>
                  <img src="assets/img/logo-wine.png" alt="Wine" class="card-parceiro_logo">
                </div>
              </article>
            </a>
          </div>
        </div>

        <div class="owl-item active" style="width: 203.333px; margin-right: 10px;">
          <div class="item">
            <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
              <article class="card-parceiro">
                <div class="card-parceiro_img" style="background-image: url(assets/img/img-parceiro-camicado.jpg);"></div>
                <div class="card-parceiro_conteudo">
                  <h5 class="titulo h5 c-branco">Deixar sua casa ainda mais bonita</h5>
                  <p class="card-parceiro_texto">10% de cashback para decorar sua casa</p>
                  <img src="assets/img/logo-camicado.png" alt="Wine" class="card-parceiro_logo">
                </div>
              </article>
            </a>
          </div>
        </div>

        <div class="owl-item active" style="width: 203.333px; margin-right: 10px;">
          <div class="item">
            <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
              <article class="card-parceiro">
                <div class="card-parceiro_img" style="background-image: url(assets/img/img-parceiro-netshoes.jpg);"></div>
                <div class="card-parceiro_conteudo">
                  <h5 class="titulo h5 c-branco">Comprar aquele tênis novo de corrida</h5>
                  <p class="card-parceiro_texto">Até 20% de desconto em artigos esportivos</p>
                  <img src="assets/img/logo-netshoes.png" alt="Wine" class="card-parceiro_logo">
                </div>
              </article>
            </a>
          </div>
        </div>

        <div class="owl-item" style="width: 203.333px; margin-right: 10px;">
          <div class="item">
            <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
              <article class="card-parceiro">
                <div class="card-parceiro_img" style="background-image: url(assets/img/img-mobly.jpg);"></div>
                <div class="card-parceiro_conteudo">
                  <h5 class="titulo h5 c-branco">Compre coisas pra sua casa</h5>
                  <p class="card-parceiro_texto">Os móveis mais lindos com 7% de desconto</p>
                  <!-- <img src="assets/img/logo-mobly.png" alt="Wine" class="card-parceiro_logo"> -->
                </div>
              </article>
            </a>
          </div>
        </div>

        <div class="owl-item" style="width: 203.333px; margin-right: 10px;">
          <div class="item">
            <a target="_blank" rel="noopener noreferrer" class="card-parceiro_link">
              <article class="card-parceiro card-parceiro_lg">
                <div class="card-parceiro_img" style="background-image: url(assets/img/img-parceiro-centauro.jpg);"></div>
                <div class="card-parceiro_conteudo">
                  <h5 class="titulo h5 c-branco">Pra você se exercitar</h5>
                  <p class="card-parceiro_texto">9% de cashback para aproveitar o melhor do esporte</p>
                  <!-- <img src="assets/img/logo-centauro.png" alt="Wine" class="card-parceiro_logo"> -->
                </div>
              </article>
            </a>
          </div>
        </div>

        <div class="owl-item" style="width: 203.333px; margin-right: 10px;">
          <div class="item">
            <a htarget="_blank" rel="noopener noreferrer" class="card-parceiro_link">
              <article class="card-parceiro">
                <div class="card-parceiro_img" style="background-image: url(assets/img/img-parceiro-movida.jpg);"></div>
                <div class="card-parceiro_conteudo">
                  <h5 class="titulo h5 c-branco">Alugue um carro para sua viagem</h5>
                  <p class="card-parceiro_texto">20% de desconto no aluguel do seu carro</p>
                  <img src="assets/img/logo-movida.png" alt="Wine" class="card-parceiro_logo">
                </div>
              </article>
            </a>
          </div>
        </div>

      </div>
    </div>

    <!-- <div class="owl-nav disabled">
      <button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span>
      </button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>
    </div> -->

    <!-- <div class="owl-dots">
      <button role="button" class="owl-dot active"><span></span></button>
      <button role="button" class="owl-dot"><span></span></button>
    </div> -->

  </div>

  <!--<div class="text-center">
      <a href="#parceiros" class="btn btn-link">conheça nosso time de parceiros</a>
  </div>-->

</section>

<!-- <section class="sect-seja-parceiro" id="seja-parceiro">
    <div class="container">
        <div class="sect-seja-parceiro_conteudo">
            <h2 class="titulo h2 c-branco text-lowercase">seja nosso parceiro </h2>
            <p class="sect-seja-parceiro_texto">Conquiste os mais de 33 milhões de usuários cadastrados no programa Km de Vantagens, monitore todas as transações em tempo real e receba em sua conta bancária.</p>
            <button type="button" class="btn btn-amarelo btn-radius modal-toggle" data-target="modalSejaParceiro">quero vender com abastece aí</button>
            <img src="assets/img/img-seja-parceiro.png" alt="Totem com QR code" class="sect-seja-parceiro_img d-none d-md-block">
            <img src="assets/img/img-seja-parceiro_mobile.png" alt="Totem com QR code" class="sect-seja-parceiro_img-mobile d-md-none">&gt;
        </div>
    </div>

    <div id="parceiros" class="sect-principais-parceiros">
        <div class="container">
            <h5 class="titulo h5 c-branco">conheça nossos <br class="d-md-none"> parceiros</h5>
            <div class="sect-principais-parceiros_container">
                <div class="circle circle-rosa"></div>
                <div class="sect-principais-parceiros_wrapper">
                    <ul class="list-unstyled parceiros-list" style="width: 5400px; transform: translateX(0px);">
                        <li><img src="assets/img/parceiro-logo-mobly.png" alt="Mobly"></li>
                        <li><img src="assets/img/parceiro-logo-netshoes.png" alt="Netshoes"></li>
                        <li><img src="assets/img/parceiro-logo-pneustore.png" alt="PneuStore"></li>
                        <li><img src="assets/img/parceiro-logo-centauro.png" alt="Centauro"></li>
                        <li><img src="assets/img/parceiro-logo-movida.png" alt="Movida"></li>
                        <li><img src="assets/img/parceiro-logo-wooza.png" alt="Wooza"></li>
                        <li><img src="assets/img/parceiro-logo-dafiti.png" alt="Dafiti"></li>
                        <li><img src="assets/img/parceiro-logo-webmotors.png" alt="webmotors"></li>
                        <li><img src="assets/img/parceiro-logo-carglass.png" alt="Carglass"></li>
                        <li><img src="assets/img/parceiro-logo-fotoregistro.png" alt="FotoRegistro"></li>
                        <li><img src="assets/img/parceiro-logo-goread.png" alt="GoRead"></li>
                        <li><img src="assets/img/parceiro-logo-hapvida.png" alt="Hapvida"></li>
                        <li><img src="assets/img/parceiro-logo-rentcars.png" alt="Rentcars"></li>
                        <li><img src="assets/img/parceiro-logo-swarovski.png" alt="Swarovski"></li>
                        <li><img src="assets/img/parceiro-logo-camicado.png" alt="Camicado"></li>
                        <li><img src="assets/img/parceiro-logo-chefsclub.png" alt="Chefsclub"></li>
                        <li><img src="assets/img/parceiro-logo-serasa.png" alt="Serasa"></li>
                        <li><img src="assets/img/parceiro-logo-wine.png" alt="Wine"></li>
                    </ul>
                </div>
                <div class="square square-verde"></div>
            </div>
            <input type="range" id="vol" name="vol" class="input-range" min="0" max="4589" value="0">
            <p class="sect-principais-parceiros_texto">Arraste para ver todos os parceiros</p>
        </div>
    </div>
</section>-->
