import {Message as EMessage} from './enum/message.enum';

export class Message {
  type: EMessage;
  description: string;
  action: string;
  link: string;
  constructor( type: EMessage, description: string, action?: string, link?: string) {
    this.type = type;
    this.description = description;
    this.action = action;
    this.link = link;
  }
}
